<template>
  <div>
    <PendingTable
      :done="1"
      :tab="getTab"
      @modalHistorySmsOpen="modalHistorySmsOpen"
      @modalSmsOpen="modalSmsOpen"
      @modalSmssOpen="modalSmssOpen"
      @revisionSale="revisionSale"
      @openModalProgram="openModalProgram"
      @openInitialPaymentModal="openInitialPaymentModal"
      @openContractFeeModal="openContractFeeModal"
      @openTrackingCapturedByModal="openTrackingCapturedByModal"
      @openTrackingModal="openTrackingModal"
      @notesModal="notesModal"
      @openUrlModal="openUrlModal"
      @openFilesModal="openFilesModal"
      @observeSale="observeSale"
      @openBinnacleModal="openBinnacleModal"
      ref="salesMadeNewPending"
    />

    <!-- SMS History Modal -->
    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="History Of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="modalData.historySms.id"
        :modul="currentUser.modul_id"
        :lead-name="modalData.historySms.leadName"
      />
    </b-modal>

    <!-- Send SMS Modal -->
    <modal-send-sms
      v-if="modal.modalSms"
      :smss="modalData.sendSms.leads_sms"
      :modul="currentUser.modul_id"
      :typesms="modalData.sendSms.typesms"
      :sms="modalData.sendSms.leads_sms_o"
      :name-leads="modalData.sendSms.name_leads_arr[0]"
      @hide="modalSmsClose"
    />

    <!-- Modal Revission - Change Date and Hour -->
    <revission-modal
      v-if="modal.revission"
      :modal="modal"
      :revission="modalData.revission"
      :toProgramId="modalData.revission.idProgram"
      @click="
        $refs.salesMadeNewPending.updateRow(selectedIndex);
        modal.revission = false;
      "
      @response="
        $refs.salesMadeNewPending.updateRow(selectedIndex);
        modal.revission = false;
      "
      @removeFromTable="
        $refs.salesMadeNewPending.removeFromTable(selectedIndex)
      "
    />

    <validate-date-and-hour
      v-if="modal.modalValidateDateAndHour"
      :modal="modal"
      :validation-data="modalData.validateDateAndHourData"
      @revission="modalRevisionAndValidateDateAndHour"
    />

    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :modal-services="modal.programs"
      :sales-client="modalData.programs.salesClient"
      :type-modal="modalData.programs.typeModal"
      @closeModal="hideModalProgram"
    />

    <!-- Modal for register Initial Payment -->
    <initial-payment-modal
      v-if="modal.initial_payment"
      :modal="modal"
      :initial_payment="modalData.initial_payment"
      @close="hideInitialPaymentModal"
    />

    <!-- Modal for configuration Contract Fee -->
    <contract-fee-modal
      v-if="modal.contract_fee"
      :modal="modal"
      :contract-fee="modalData.contractFee"
      @close="modal.contract_fee = false"
      @reload="$refs.salesMadeNewPending.updateRow(selectedIndex)"
    />

    <tracking-captured-by-modal
      v-if="modal.captuerd_by_tracking"
      :modal="modal"
      :captured-by-tracking="modalData.capturedByTracking"
    />

    <tracking-modal :modal="modal" :tracking="modalData.tracking" />

    <!-- NOTES -->
    <component
      :is="modalData.notes.programSelected"
      v-if="modal.notes"
      :note-info="modalData.notes"
      @hide="closeModalNotes"
    />

    <files-modal
      v-if="modal.files"
      :modal="modal"
      :files="modalData.files"
      @close="modal.files = false"
    />

    <!-- Modal for Generate Url Lead -->
    <url-modal v-if="modal.url" :modal="modal" :url="modalData.url" />

    <ObserveModal
      v-if="modal.observe"
      :modal="modal"
      :observe="modalData.observe"
      @hideModal="responseAction"
    />

    <BinnacleModal
      v-if="modal.binnacle"
      :binnacle="modalData.binnacle"
      @close="hideBinnacleModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import PendingTable from "@/views/debt-solution/views/sales-made/components/pending/PendingTable.vue";

/* Modals */
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import FilesModal from "@/views/crm/views/sales-made/components/modals/FilesModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import InitialPaymentModal from "@/views/crm/views/sales-made/components/modals/InitialPaymentModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ModalHistorySms from "@/views/crm/views/Lead/lead-sms/ModalHistorySms.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import RevissionModal from "@/views/crm/views/sales-made/components/modals/RevissionModal.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import TrackingCapturedByModal from "@/views/crm/views/sales-made/components/modals/TrackingCapturedByModal.vue";
import TrackingModal from "@/views/crm/views/sales-made/components/modals/TrackingModal.vue";
import UrlModal from "@/views/crm/views/sales-made/components/modals/UrlModal.vue";
import ValidateDateAndHour from "@/views/crm/views/sales-made/components/modals/ValidateDateAndHour.vue";
import RegionCreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/RegionCreditExpertsModal.vue";

import ObserveModal from "@/views/debt-solution/views/sales-made/modal/ObservedModal.vue";
import BinnacleModal from "@/views/debt-solution/views/sales-made/modal/BinnacleModal.vue";
/* Services */
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils/";

export default {
  components: {
    PendingTable,
    BoostCreditModal,
    BusinessModal,
    ContractFeeModal,
    CreditExpertsModal,
    DebtSolutionModal,
    FilesModal,
    GeneralSupportModal,
    InitialPaymentModal,
    KeyBookModal,
    ModalHistorySms,
    ModalSendSms,
    ModalNotesBoost,
    ModalNotesParagon,
    ModalNotesFirst,
    ModalNotesTax,
    ModalNotesCredit,
    ModalNotesAll,
    ModalNotesSpecialist,
    ParagonModal,
    RevissionModal,
    SpecialistModal,
    TaxResearchModal,
    TrackingCapturedByModal,
    TrackingModal,
    UrlModal,
    ValidateDateAndHour,
    RegionCreditExpertsModal,
    ObserveModal,
    BinnacleModal,
  },
  data() {
    return {
      tabIndex: null,
      title: {
        tab1: this.moduleId == 4 ? "In Process" : "Done",
        tab2: this.moduleId == 4 ? "Approved" : "Pending",
      },
      modalData: {
        url: {
          client: "",
          program: "",
          amount: 0.0,
        },
        files: {
          id: null,
          program: "",
          client: "",
          sale_id: null,
          valorEdit: false,
        },
        historySms: {
          id: null,
          leadName: "",
        },
        tracking: {
          program: "",
          client: "",
          tabla: [],
        },
        initial_payment: {
          payments: null,
          nameProgram: null,
          nameClient: null,
          type: null,
          editmodal: null,
          statusSale: null,
          sessionId: null,
          valorInitalPayment: null,
          feeprops: null,
          modul: null,
          cfeestatus: null,
          idtransaction: null,
          programid: null,
          allcards: null,
          role_id: null,
          type_payment_ce: null,
        },
        capturedByTracking: {
          program: "",
          client: "",
          id: 0,
        },
        sendSms: {
          leads_sms: [],
          typesms: null,
          leads_sms_o: [],
          name_leads_arr: [],
        },
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
        revission: {},
        observe: {},
        binnacle: {},
        contractFee: {
          programName: "",
          clientName: "",
          saleId: null,
          id: null,
          initialPaymentStatus: null,
          editModal: false,
          statusSale: null,
          st: null,
          type_payment_ce: null,
        },
        notes: {
          programSelected: "",
          roleId: null,
          notesProgram: null,
          nameProgram: null,
          nameClient: null,
          salesMades: null,
          module: null,
          type: null,
          editModal: false,
          statusSale: null,
          sourcesName: null,
          pack: null,
          created: null,
          originCountry: null,
          idLead: null,
          notSeller: null,
          capturedName: null,
          sellerName: null,
          trackings: null,
          notes_status: null,
          notes_status_new: null,
        },
        validateDateAndHourData: {
          program: "",
          client: "",
          saleId: null,
          contactSchedule: null,
          contactHour: null,
        },
        isUrgent: false,
      },
      modal: {
        tracking: false,
        initial_payment: false,
        captuerd_by_tracking: false,
        files: false,
        programs: false,
        revission: false,
        url: false,
        contract_fee: false,
        notes: false,
        approveSupervisorModal: false,
        modalSms: false,
        modalValidateDateAndHour: false,
        observe: false,
        binnacle: false,
      },
      selectedIndex: null,
    };
  },
  computed: {
    getTab() {
      return this.$route.meta.tab;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_ASSIST_SUPERVISOR: "auth/isAssistantSupervisor",
      G_CHIEF: "auth/isChief",
      G_IS_SUPERVISOR: "auth/isSupervisor",
      G_IS_SELLER: "auth/isSeller",
      G_IS_CEO: "auth/isCeo",
      G_MODULE_ID: "auth/moduleId",
      G_USER_ROLE: "auth/userRole",
      G_IS_ADVISOR: "auth/isAdvisor",
      G_USER_SESSION: "auth/userSession",
      G_ROLE_ID: "auth/roleId",
    }),
  },

  methods: {
    modalHistorySmsOpen(item) {
      this.modalData.historySms.leadName = item.client;
      this.modalData.historySms.id = item.lead_id;
      this.$bvModal.show("modal-history-sms");
    },
    modalSmssOpen(selected) {
      this.modalData.sendSms.typesms = 0;
      this.modalData.sendSms.name_leads_arr = selected.map((el) => ({
        name: el.client,
        id: el.lead_id,
      }));
      this.modalData.sendSms.leads_sms = selected.map((el) => el.lead_id);
      this.modal.modalSms = true;
    },
    modalSmsOpen(item) {
      this.modalData.sendSms.leads_sms = [];
      this.modalData.sendSms.typesms = 1;
      this.modalData.sendSms.leads_sms_o = [];
      this.modalData.sendSms.leads_sms_o.push(item.lead_id);
      this.modalData.sendSms.name_leads_arr = [
        { name: item.client, id: item.lead_id },
      ];
      this.modal.modalSms = true;
    },
    modalSmsClose() {
      this.modal.modalSms = false;
    },
    async revisionSale(dataRevission) {
      const { state, data, index } = dataRevission;
      this.selectedIndex = index;
      this.modalData.revission.nameProgram = data.program;
      this.modalData.revission.idProgram = data.program_id;
      this.modalData.revission.client_type_id = data.client_type_id;
      this.modalData.revission.nameClient = data.client;
      this.modalData.revission.type = state;
      this.modalData.revission.idsales = data.id;
      this.modalData.revission.initialPayment = data.initial_payment_status;
      this.modalData.revission.account = data.account;
      this.modalData.revission.leadId = data.lead_id;
      this.modalData.revission.datevent = data.event_date;
      this.modalData.revission.sellerName = data.seller;
      this.modalData.revission.language = data.language;
      this.modalData.revission.user_id = this.currentUser.user_id;
      this.modalData.revission.last_name = data.last_name;
      this.modalData.revission.seller_id = data.seller_id;

      this.modalData.revission.comes_from = data?.comes_from;
      this.modalData.validateDateAndHourData.saleId = data.id;
      this.modalData.validateDateAndHourData.program = data.program;
      this.modalData.validateDateAndHourData.client = data.client;
      this.modalData.isUrgent = !!parseInt(data.is_urgent);

      if (!!parseInt(data.is_urgent)) {
        this.modal.revission = true;
      } else {
        await this.validateDateAndHourDataV();
        this.modal.revission = this.modal.modalValidateDateAndHour !== true;
      }
    },
    async observeSale(dataRevission) {
      const { state, data, index } = dataRevission;
      this.selectedIndex = index;
      this.modalData.observe.nameProgram = data.program;
      this.modalData.observe.idProgram = data.program_id;
      this.modalData.observe.nameClient = data.client;
      this.modalData.observe.type = state;
      this.modalData.observe.idsales = data.id;
      this.modalData.observe.initialPayment = data.initial_payment_status;
      this.modalData.observe.account = data.account;
      this.modalData.observe.leadId = data.lead_id;
      this.modalData.observe.datevent = data.event_date;
      this.modalData.observe.sellerName = data.seller;
      this.modalData.observe.language = data.language;
      this.modalData.observe.user_id = this.currentUser.user_id;
      this.modalData.observe.last_name = data.last_name;
      this.modalData.observe.seller_id = data.seller_id;
      this.modalData.observe.mobile = data.mobile;
      this.modalData.validateDateAndHourData.saleId = data.id;
      this.modalData.validateDateAndHourData.program = data.program;
      this.modalData.validateDateAndHourData.client = data.client;
      this.modal.observe = true;
    },
    async validateDateAndHourDataV() {
      const data = await NotesServices.validateDateAndHourRevision({
        sale_id: this.modalData.revission.idsales,
      });
      if (data.status === 200) {
        if (data) {
          if (data.data.length === 2) {
            const now = moment();
            const exp = moment(`${data.data[0].answer} ${data.data[1].answer}`);

            const days = exp.diff(now, "hours");
            if (days <= 47) {
              this.modalData.validateDateAndHourData.contactDate =
                data.data[0].answer;
              this.modalData.validateDateAndHourData.contactHour =
                data.data[1].answer;
              this.modal.modalValidateDateAndHour = true;
              this.contactHourSecondsValidate48hours = false;
            } else {
              this.contactHourSecondsValidate48hours = true;
            }
          }
        }
      }
    },
    modalRevisionAndValidateDateAndHour() {
      this.modal.revission = true;
    },
    responseAction(action) {
      if (action == true) {
        this.$refs.salesMadeNewPending.refresh();
      }
      this.modal.observe = false;
    },
    openModalProgram(dataPrograms) {
      const { data, index } = dataPrograms;

      this.selectedIndex = index;

      switch (data.program_id) {
        case 1:
          this.modalData.programs.programSelected = "business-modal";
          break;
        case 2:
          this.modalData.programs.programSelected = "boost-credit-modal";
          break;
        case 3:
          if (
            data.type_payment_ce !== null ||
            (["FL", "TX", "NV", "AZ", "NM"].includes(data.slug_state) &&
              moment(data.creates).isAfter("2023-01-03"))
          ) {
            this.modalData.programs.programSelected =
              "region-credit-experts-modal";
            break;
          }
          this.modalData.programs.programSelected = "credit-experts-modal";
          break;
        case 4:
          this.modalData.programs.programSelected = "debt-solution-modal";
          break;
        case 5:
          this.modalData.programs.programSelected = "tax-research-modal";
          break;
        case 6:
          this.modalData.programs.programSelected = "general-support-modal";
          break;
        case 7:
          this.modalData.programs.programSelected = "specialist-modal";
          break;
        case 8:
          this.modalData.programs.programSelected = "key-book-modal";
          break;
        case 9:
          this.modalData.programs.programSelected = "paragon-modal";
          break;
        default:
          break;
      }
      if (this.modalData.programs.programSelected !== "") {
        this.modalData.programs.typeModal =
          data.haveRates == 1 &&
          !(data.program_id == 4 && data.initial_payment_status == 1)
            ? 2
            : 1;
        this.modalData.programs.salesClient = data;
        this.modal.programs = true;
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        this.$refs.salesMadeNewPending.updateRow();
      }
      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    async openInitialPaymentModal(dataIP) {
      try {
        const { data, index } = dataIP;

        this.selectedIndex = index;
        this.addPreloader();
        this.modalData.initial_payment.programid = data.program_id;
        this.modalData.initial_payment.type_payment_ce = data.type_payment_ce;
        this.modalData.initial_payment.sessionId = this.currentUser.user_id;
        this.modalData.initial_payment.cfeestatus = data.contract_fee_status;
        this.modalData.initial_payment.id_transaction = data.transaction_id;
        this.modalData.initial_payment.editmodal =
          data.user_id == this.currentUser.user_id ||
          this.G_IS_CEO ||
          this.G_IS_SUPERVISOR ||
          this.isCoordinator ||
          this.isTeamLeader ||
          (this.G_IS_CHIEF && this.moduleId === 2 && data.from_module !== 26) ||
          (this.G_IS_CHIEF &&
            this.moduleId === 26 &&
            data.from_module === 26) ||
          this.G_IS_ASSIST_SUPERVISOR ||
          (this.G_IS_SUPERVISOR &&
            this.moduleId === 2 &&
            data.from_module !== 26) ||
          (this.G_IS_SUPERVISOR &&
            this.moduleId === 26 &&
            data.from_module === 26);
        this.isChief;
        this.modalData.initial_payment.statusSale = data.status;
        this.modalData.initial_payment.nameProgram = data.program;
        this.modalData.initial_payment.nameClient = data.client;
        this.modalData.initial_payment.valorInitalPayment =
          data.initial_payment_status;
        this.modalData.initial_payment.feeprops = data.fee;
        const cards = await amgApi.post("/clients/search-cards-clients", {
          id: data.lead_id,
        });
        if (cards.status === 200) {
          this.modalData.initial_payment.allcards = cards.data;
        }
        const response = await amgApi.post(
          "/sales-made/get-payments-sales-made",
          { id: data.id }
        );
        if (response.status === 200) {
          [this.modalData.initial_payment.payments] = response.data;
        }
        this.modalData.initial_payment.modul = 2;
        this.modalData.initial_payment.role_id = this.currentUser.role_id;
        this.modal.initial_payment = true;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    async hideInitialPaymentModal(val) {
      this.modal.initial_payment = false;
      if (val) {
        this.$refs.salesMadeNewPending.updateRow(this.selectedIndex);
      }
    },
    openContractFeeModal(dataCF) {
      const { data, index } = dataCF;

      this.selectedIndex = index;

      if (
        data.user_id == this.currentUser.user_id ||
        this.G_IS_CEO ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 2 &&
          data.from_module !== 26) ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 26 &&
          data.from_module === 26) ||
        this.isCoordinator ||
        this.isTeamLeader ||
        this.isAssistantSupervisor ||
        (this.isChief && this.moduleId === 2 && data.from_module !== 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module === 26)
      ) {
        this.modalData.contractFee.editModal = true;
      } else {
        this.modalData.contractFee.editModal = false;
      }
      this.modalData.contractFee.type_payment_ce = data.type_payment_ce;
      this.modalData.contractFee.statusSale = data.status;
      this.modalData.contractFee.clientName = data.client;
      this.modalData.contractFee.programName = data.program;
      this.modalData.contractFee.id = data.lead_id;
      this.modalData.contractFee.saleId = data.id;
      this.modalData.contractFee.seller_id = data.seller_id;
      this.modalData.contractFee.initialPaymentStatus =
        data.initial_payment_status;
      this.modalData.contractFee.initialAmount = data.initial_amount;
      this.modal.contract_fee = true;
    },
    openTrackingCapturedByModal(dataTrackingCapture) {
      const { program, client, id, type } = dataTrackingCapture;
      this.modalData.capturedByTracking.client = client;
      this.modalData.capturedByTracking.program = program;
      this.modalData.capturedByTracking.id = id;
      this.modalData.capturedByTracking.type = type;
      if (type === 1) this.modalData.capturedByTracking.tittle = "CAPTURED BY";
      else if (type === 2) this.modalData.capturedByTracking.tittle = "SELLER";
      else if (type === 3) this.modalData.capturedByTracking.tittle = "FEE";
      this.modal.captuerd_by_tracking = true;
    },
    openTrackingModal(dataTracking) {
      const { program, client, tabla, saleId } = dataTracking;
      this.modalData.tracking.program = program;
      this.modalData.tracking.client = client;
      this.modalData.tracking.saleId = saleId;
      if (tabla) {
        this.modalData.tracking.tabla = JSON.parse(tabla) || [];
        this.modal.tracking = true;
      }
    },
    async notesModal(dataNotes) {
      const { data, index } = dataNotes;

      this.addPreloader();
      this.modalData.notes.event_id = data.event_id;
      this.modalData.notes.capturedName = data.captured;
      this.modalData.notes.sellerName = data.seller;
      this.modalData.notes.trackings = data.trackings;
      this.modalData.notes.nameProgram = data.program;
      this.modalData.notes.nameClient = data.client;
      this.modalData.notes.statusSale = data.status;
      this.modalData.notes.sourcesName = data.sourcesname;
      this.modalData.notes.pack = data.pack;
      this.modalData.notes.originCountry = data.origin_country;
      this.modalData.notes.idLead = data.lead_id;
      this.modalData.notes.created = data.creates;
      this.modalData.notes.saleId = data.id;
      this.modalData.notes.module = this.G_MODULE_ID;
      this.modalData.notes.rolId = this.G_ROLE_ID;
      this.modalData.notes.notes_status = data.notes_status;
      this.modalData.notes.notes_status_new = data.notes_status_new;
      this.modalData.notes.seller_id = data.seller_id;
      this.modalData.notes.editModal =
        this.G_IS_CEO ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 2 &&
          data.from_module !== 26) ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 26 &&
          data.from_module === 26) ||
        (this.isChief && this.moduleId === 2 && data.from_module !== 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module === 26) ||
        this.isCoordinator ||
        this.isTeamLeader ||
        this.G_USER_SESSION == data.user_id;
      this.modalData.notes.notSeller =
        (data.user_id != this.G_USER_SESSION && this.G_IS_SELLER) || //(data.from_module !== 26 ? this.G_IS_SELLER : (this.G_IS_SUPERVISOR && this.moduleId === 2 && data.from_module === 26))
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 2 &&
          data.from_module === 26) ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 26 &&
          data.from_module !== 26) ||
        (this.isChief && this.moduleId === 2 && data.from_module === 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module !== 26);

      if (this.moduleId === 26) {
        this.modalData.notes.notSeller =
          data.seller_id != this.G_USER_SESSION && this.G_IS_SELLER;
        this.modalData.notes.editModal =
          this.G_IS_CEO ||
          this.G_IS_SUPERVISOR ||
          this.G_IS_ASSIST_SUPERVISOR ||
          this.isCoordinator ||
          this.isTeamLeader ||
          this.G_USER_SESSION == data.seller_id ||
          this.isChief;
      }

      //If is not seller,but is Coordinator
      // if (this.isCoordinator || this.isTeamLeader) {
      //   this.modalData.notes.notSeller = false;
      // }
      this.openModalNotes(data.creates, data.program_id, index);
    },
    openModalNotes(created, program, index) {
      this.selectedIndex = index;
      switch (true) {
        case created >= "2020-05-28" && program == 1:
          this.modalData.notes.programSelected = "ModalNotesFirst"; // ready
          break;
        case created >= "2020-10-29" && program == 2:
          this.modalData.notes.programSelected = "ModalNotesBoost"; // ready
          break;
        case created >= "2021-03-04" && program == 3:
          this.modalData.notes.programSelected = "ModalNotesCredit"; // ready
          break;
        case created >= "2020-09-24" && program == 5:
          this.modalData.notes.programSelected = "ModalNotesTax"; // ready
          break;
        case created >= "2020-10-23" && program == 7:
          this.modalData.notes.programSelected = "ModalNotesSpecialist"; // ready
          break;
        case program == 9:
          this.modalData.notes.programSelected = "ModalNotesParagon"; // ready
          break;
        default:
          // next
          this.modalData.notes.programSelected = "ModalNotesAll";
          break;
      }
      this.modal.notes = true;
    },
    async closeModalNotes(status) {
      this.modal.notes = false;
      if (status) {
        this.$refs.salesMadeNewPending.updateRow(this.selectedIndex);
        this.removePreloader();
        this.showSuccessSwal("OPERATION SUCCESSFULLY");
      }
    },
    openUrlModal(data) {
      this.modalData.url.client = data.client;
      this.modalData.url.program = data.program;
      this.modalData.url.selectedLead = data;
      this.modal.url = true;
    },
    openFilesModal(dataFile) {
      const {
        id,
        program,
        client,
        saleId,
        status,
        sellerId,
        programId,
        eventDate,
      } = dataFile;
      this.modalData.files.id = id;
      this.modalData.files.program = program;
      this.modalData.files.client = client;
      this.modalData.files.sale_id = saleId;
      this.modalData.files.programId = programId;
      this.modalData.files.event_date = eventDate;
      const isCeoOrSupervisor =
        this.currentUser.role_id == "1" || this.currentUser.role_id == "2";
      const statusNotInRevisionOrApproved = !(status == "4" || status == "2");
      if (
        ((this.currentUser.user_id == sellerId ||
          isCeoOrSupervisor ||
          this.isChief ||
          this.isAssistantSupervisor) &&
          statusNotInRevisionOrApproved) ||
        (isCeoOrSupervisor && status == "2" && module == 4)
      ) {
        this.modalData.files.valorEdit = true;
      }
      this.modal.files = true;
    },
    openBinnacleModal(data) {
      this.modalData.binnacle.nameProgram = data.data.program;
      this.modalData.binnacle.idProgram = data.data.program_id;
      this.modalData.binnacle.client_type_id = data.data.client_type_id;
      this.modalData.binnacle.nameClient = data.data.client;
      // this.modalData.binnacle.type = state;
      this.modalData.binnacle.idsales = data.data.id;
      this.modalData.binnacle.initialPayment = data.data.initial_payment_status;
      this.modalData.binnacle.account = data.data.account;
      this.modalData.binnacle.leadId = data.data.lead_id;
      this.modalData.binnacle.datevent = data.data.event_date;
      this.modalData.binnacle.sellerName = data.data.seller;
      this.modalData.binnacle.language = data.data.language;
      this.modalData.binnacle.user_id = this.currentUser.user_id;
      this.modalData.binnacle.last_name = data.data.last_name;
      this.modalData.binnacle.seller_id = data.data.seller_id;

      this.modalData.binnacle.comes_from = data.data?.comes_from;

      this.modalData.binnacle.clientName = data.data.client;
      this.modalData.binnacle.mobile = data.data.mobile;
      this.modalData.binnacle.state = data.data.state;
      this.modalData.binnacle.status = data.data.status;
      this.modalData.binnacle.sale_id = data.data.id;
      this.modalData.binnacle.seller = data.data.seller;
      this.modalData.binnacle.origin = "DS";
      this.modal.binnacle = true;
    },
    hideBinnacleModal(action = false) {
      if (action == true) {
        this.$refs.salesMadeNewPending.refresh();
      }
      this.modal.binnacle = false;
    },
  },
  created() {
    if (this.$route.query.done) {
      this.tabIndex = this.$route.query.done - 1;
    } else {
      this.tabIndex = 0;
    }
  },
  mounted() {
    if (this.moduleId == 4) {
      this.title.tab1 = "In Process";
      this.title.tab2 = "Approved";
    }
  },
  beforeRouteEnter(to, from, next) {
    const userData = getUserData();
    // Check if user can navigate directly to the specified route (using user's role and software engineering level)
    if (!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)) {
      return next({ name: "misc-not-authorized" });
    } else {
      return next();
    }
  },
};
</script>

<style>
</style>