<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['new-client-done-table'].refresh()"
      @onSelectChange="getSellers()"
    >
      <!-- <template #buttons>
        <b-button
          variant="success"
          class="ml-1"
          :disabled="!selected.length"
          @click="$emit('modalSmssOpen', selected)"
        >
          <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
        </b-button>
      </template> -->
      <template #table>
        <b-table
          id="new-client-done-table"
          ref="new-client-done-table"
          sticky-header="70vh"
          small
          show-empty
          no-provider-filtering
          :busy.sync="isBusy"
          responsive="sm"
          :items="statusEmit ? removeFromTable : search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          :tbody-tr-class="'tr-table'"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template> -->
          <template v-slot:cell(selected)="row">
            <b-form-checkbox
              v-if="false"
              v-model="row.item.selected"
              class="mt-1"
              @input="selectedRow(row.item)"
            />
          </template>
          <template v-slot:cell(client)="data">
            <div class="text-left" style="margin-bottom: -15px !important">
              <router-link
                :class="[textLink]"
                :to="{
                  name: toRoute,
                  params: {
                    id: data.item.lead_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client }}</router-link
              >
              <FeatherIcon
                v-if="parseInt(data.item.is_urgent) === 1"
                icon="InfoIcon"
                v-b-tooltip.hover.right.v-danger
                title="IS URGENT"
                class="ml-1 text-danger cursor-pointer"
                size="20"
              />
              <tabler-icon
                v-if="data.item.status == 8 || data.item.total_message > 0"
                icon="MessageCircleIcon"
                :badge="
                  moduleId == 5 && data.item.binnacle_origin == 'CRM'
                    ? data.item.binnacle_cont
                    : moduleId == 2 && data.item.binnacle_origin == 'DS'
                    ? data.item.binnacle_cont
                    : 0
                "
                :badgeClasses="'bg-danger'"
                class="text-primary ml-1 cursor-pointer"
                size="22"
                @click="openBinnacleModal(data.item, data.index)"
              />
              <p class="mb-0">
                {{ data.item.mobile }}
              </p>
              <p class="mb-0">
                <small>{{ data.item.state }}, {{ data.item.country }}</small>
              </p>
              <p>
                <small>{{ data.item.sourcesname }}</small>

                <small v-if="![1, 2].includes(data.item.idSubSource)">
                  ({{ data.item.nameSubSource }})</small
                >
                <feather-icon
                  v-if="data.item.sourcesname == 'Facebook'"
                  v-b-tooltip.hover
                  class="cursor-pointer"
                  :class="
                    data.item.flyer_route ? 'text-primary' : 'text-secondary'
                  "
                  icon="ExternalLinkIcon"
                  title="View flyer"
                  @click="openViewFlyer(data.item)"
                />
              </p>
            </div>
          </template>
          <template #cell(info)="{ item }">
            <b-col>
              <b-row>
                <b>Doc type: </b>
                {{
                  item.ssn
                    ? "ssn"
                    : item.cpn
                    ? "cpn"
                    : item.itin
                    ? "itin"
                    : "none"
                }}</b-row
              >
              <b-row> <b>O. country: </b> {{ item.country_name }}</b-row>
              <b-row> <b>Work staus: </b> {{ item.work_status }}</b-row>
              <b-row> <b>Legal status: </b> {{ item.legal_status }}</b-row>
              <b-row v-if="item.sourcesname == 'Facebook' && item.flyer_code">
                <b>Flyer code: </b> {{ item.flyer_code }}</b-row
              >
            </b-col>
          </template>
          <template v-slot:cell(program)="data">
            <div class="d-flex justify-content-center">
              <div>
                <b-button
                  :style="`color: white !important; border-color: ${data.item.program_color} !important; background-color: ${data.item.program_color} !important;`"
                  size="sm"
                  class="font-weight-bolder w-100"
                  @click="openModalProgram(data.item, data.index)"
                >
                  <span v-b-tooltip.bottom="data.item.program">{{
                    data.item.program_initials
                  }}</span>
                  <feather-icon
                    v-if="data.item.haveRates !== 1"
                    icon="AlertTriangleIcon"
                    style="margin-left: 5px"
                  />
                  <feather-icon
                    v-else
                    icon="CheckCircleIcon"
                    style="margin-left: 5px"
                  />
                </b-button>
                <!-- Sale from program Debt Solution -->
                <analysis-option-badge
                  v-if="data.item.ds_analysis_options_id"
                  :data="data.item"
                />
              </div>
            </div>
          </template>
          <template #cell(captured)="data">
            <b-col class="align-content-between p-0">
              <b-row>
                <b-col
                  class="d-flex align-items-center justify-content-between"
                >
                  <span
                    v-if="!data.item.editSeller"
                    class="text-info"
                    v-b-tooltip.hover.top="`Seller`"
                  >
                    {{ data.item.seller }}
                  </span>
                  <b-form-select
                    v-else
                    v-model="data.item.sellerNew"
                    text-field="label"
                    value-field="id"
                    :options="sellers"
                    class="mb-1 font-small-1"
                    style="width: 80px !important"
                    size="sm"
                  />

                  <p v-if="data.item.commission">
                    <small
                      v-if="
                        [1, 2, 17].includes(+currentUser.role_id) ||
                        (data.item.user_id == currentUser.user_id &&
                          G_IS_SELLER)
                      "
                      class="text-primary font-weight-bold"
                    >
                      $ {{ data.item.commission[1].commission }}</small
                    >
                  </p>
                  <span
                    v-if="
                      [1, 3, 5].includes(+data.item.status) &&
                      ([1, 2, 11, 14, 17].includes(+currentUser.role_id) ||
                        isCoordinator) &&
                      (moduleId === data.item.from_module ||
                        (moduleId !== 26 && data.item.from_module !== 26))
                    "
                    class="mt-07 text-right mr-1"
                  >
                    <b-icon
                      v-if="!data.item.editSeller"
                      icon="pencil-fill"
                      class="cursor-pointer"
                      @click="data.item.editSeller = true"
                    />
                    <feather-icon
                      v-else
                      class="cursor-pointer"
                      icon="SaveIcon"
                      @click="
                        saveNewSeller(
                          data.item.seller,
                          data.item.sellerNew,
                          data.item.id,
                          data.item
                        )
                      "
                    />
                    <b-icon
                      v-if="!data.item.editSeller"
                      class="cursor-pointer ml-07"
                      icon="list-ul"
                      @click="
                        openTrackingCapturedByModal(
                          data.item.program,
                          data.item.client,
                          data.item.id,
                          2
                        )
                      "
                    />
                    <feather-icon
                      v-else
                      class="cursor-pointer"
                      icon="XSquareIcon"
                      @click="
                        data.item.editSeller = false;
                        data.item.sellerNew = data.item.seller;
                      "
                    />
                  </span>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col
                  class="d-flex align-items-center justify-content-between"
                >
                  <span
                    v-if="!data.item.editCaptured"
                    class="text-warning"
                    v-b-tooltip.hover.top="`Catcher`"
                  >
                    {{ data.item.captured }}
                  </span>
                  <b-form-select
                    v-else
                    v-model="data.item.capturedNew"
                    text-field="label"
                    value-field="id"
                    :options="captured"
                    class="mb-1 font-small-1"
                    style="width: 80px !important"
                    size="sm"
                  />

                  <p v-if="data.item.commission" class="m-0">
                    <small
                      v-if="G_IS_CEO || G_IS_SUPERVISOR || isChief"
                      class="text-primary font-weight-bold"
                      >$ {{ data.item.commission[0].commission }}
                    </small>
                  </p>

                  <span
                    v-if="
                      [1, 3, 5].includes(+data.item.status) &&
                      ([1, 2, 11, 14, 17].includes(+currentUser.role_id) ||
                        isCoordinator)
                    "
                    class="mt-07 text-right mr-1"
                  >
                    <b-icon
                      v-if="!data.item.editCaptured"
                      icon="pencil-fill"
                      class="cursor-pointer"
                      @click="
                        data.item.editCaptured = true;
                        data.item.editCaptured = data.item.captured;
                      "
                    />
                    <feather-icon
                      v-else
                      class="cursor-pointer"
                      icon="SaveIcon"
                      @click="
                        saveNewCaptured(
                          data.item.captured,
                          data.item.capturedNew,
                          data.item.id,
                          data.item
                        )
                      "
                    />
                    <b-icon
                      v-if="!data.item.editCaptured"
                      class="cursor-pointer ml-07"
                      icon="list-ul"
                      @click="
                        openTrackingCapturedByModal(
                          data.item.program,
                          data.item.client,
                          data.item.id,
                          1
                        )
                      "
                    />
                    <feather-icon
                      v-else
                      class="cursor-pointer"
                      icon="XSquareIcon"
                      @click="data.item.editCaptured = false"
                    />
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-slot:cell(fee)="data">
            <span v-if="Boolean(data.item.type_payment_ce)">
              $
              {{
                typeof data.item.fee === "number"
                  ? data.item.fee.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : data.item.fee
              }}
              <span class="text-muted">
                ({{ data.item.type_payment_ce === 1 ? "SP" : "MP" }})
              </span>
            </span>
            <span
              v-else
              class="d-flex align-items-center justify-content-between"
            >
              <span v-if="!data.item.editFee">
                $
                {{
                  typeof data.item.fee === "number"
                    ? data.item.fee.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : data.item.fee
                }}
              </span>
              <span v-else>
                <money
                  v-model="data.item.feeNew"
                  class="form-control form-control-sm p-0"
                  v-bind="{ prefix: ' $ ', precision: 2 }"
                  style="width: 70px !important"
                />
              </span>

              <span
                v-if="
                  (data.item.status === 1 || data.item.status === 3) &&
                  (G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isChief ||
                    isCoordinator ||
                    isTeamLeader)
                "
                class="mt-07 text-right mr-1"
              >
                <b-icon
                  v-if="!data.item.editFee"
                  icon="pencil-fill"
                  class="cursor-pointer"
                  @click="
                    !(data.item.haveRates !== 1) && (data.item.editFee = true)
                  "
                />
                <feather-icon
                  v-else
                  class="cursor-pointer"
                  icon="SaveIcon"
                  @click="
                    saveNewFee(
                      data.item.fee,
                      data.item.feeNew,
                      data.item.id,
                      data.item
                    )
                  "
                />
                <b-icon
                  v-if="!data.item.editFee"
                  icon="list-ul"
                  class="cursor-pointer ml-07"
                  @click="
                    openTrackingCapturedByModal(
                      data.item.program,
                      data.item.client,
                      data.item.id,
                      3
                    )
                  "
                />
                <feather-icon
                  v-else
                  class="cursor-pointer"
                  icon="XSquareIcon"
                  @click="
                    data.item.editFee = false;
                    data.item.feeNew = data.item.fee;
                  "
                />
              </span>
              <template
                v-if="
                  (data.item.status === 1 || data.item.status === 3) &&
                  G_IS_SELLER
                "
              >
                <br />
                <b-icon
                  v-if="!data.item.editFee"
                  icon="list-ul"
                  class="cursor-pointer ml-07"
                  @click="
                    openTrackingCapturedByModal(
                      data.item.program,
                      data.item.client,
                      data.item.id,
                      3
                    )
                  "
                />
              </template>
            </span>
          </template>
          <template v-slot:cell(initial_amount)="data">
            <div
              :class="
                data.item.user_id == currentUser.user_id ||
                G_IS_SELLER ||
                userCanSell[0].can_sell == 1 ||
                G_IS_CEO ||
                G_IS_SUPERVISOR ||
                isChief ||
                isCoordinator ||
                isTeamLeader ||
                isAssistantSupervisor
                  ? 'cursor-pointer'
                  : ''
              "
              @click="
                (data.item.user_id == currentUser.user_id ||
                  G_IS_SELLER ||
                  userCanSell[0].can_sell == 1 ||
                  G_IS_CEO ||
                  G_IS_SUPERVISOR ||
                  isChief ||
                  isCoordinator ||
                  isTeamLeader ||
                  isAssistantSupervisor) &&
                  openInitialPaymentModal(data.item, data.index)
              "
            >
              <b-icon
                v-if="
                  data.item.initial_payment_status === 1 &&
                  data.item.initial_amount == 0
                "
                icon="wallet2"
                variant="muted"
                class="font-medium-2"
              />
              <b-icon
                v-else-if="data.item.initial_payment_status === 3"
                icon="wallet2"
                variant="warning"
                class="font-medium-2"
              />
              <p
                v-else-if="
                  data.item.initial_payment_status === 2 ||
                  (data.item.initial_payment_status === 1 &&
                    data.item.initial_amount > 0)
                "
                class="text-success font-weight-bold"
              >
                $ {{ data.item.initial_amount }}
              </p>
            </div>
          </template>

          <template v-slot:cell(contract_fee_status)="data">
            <b-col
              :style="
                data.item.user_id != currentUser.user_id && G_IS_SELLER
                  ? 'pointer-events: none !important; opacity: 0.4 !important;'
                  : ''
              "
              class="d-flex justify-content-start p-0"
            >
              <b-icon
                v-if="
                  data.item.contract_fee_status == 0 ||
                  (data.item.contract_fee_status == 1 &&
                    data.item.initial_payment_status == 3)
                "
                @click="showContractFee(data)"
                class="cursor-pointer font-medium-2"
                icon="file-text"
                variant="muted"
                :style="
                  data.item.initial_amount > 0 ? '' : 'cursor: not-allowed;'
                "
                v-b-tooltip.hover.top="'Contract Fee'"
              />
              <b-icon
                v-if="
                  data.item.contract_fee_status == 1 &&
                  data.item.initial_payment_status == 2
                "
                @click="showContractFee(data)"
                variant="success"
                icon="file-text"
                class="cursor-pointer font-medium-2"
                :style="
                  data.item.initial_payment_status == 2
                    ? ''
                    : 'cursor: not-allowed;'
                "
                v-b-tooltip.hover.top="'Contract Fee'"
              />
              <b-icon
                v-if="data.item.contract_fee_status == 2"
                @click="showContractFee(data)"
                variant="danger"
                icon="file-text"
                class="cursor-pointer font-medium-2"
                :style="
                  data.item.initial_payment_status == 2
                    ? ''
                    : 'cursor: not-allowed;'
                "
                v-b-tooltip.hover.top="'Contract Fee'"
              />
            </b-col>
          </template>
          <template v-slot:cell(notes_status)="data">
            <b-col class="d-flex justify-content-start p-0">
              <b-icon
                v-if="data.item.creates > '2021-05-16 00:00:00'"
                @click="notesModal(data.item, data.index)"
                icon="chat-square-text-fill"
                class="cursor-pointer font-medium-2"
                :variant="
                  data.item.notes_status_new == null && moduleId != 4
                    ? 'muted'
                    : data.item.notes_status_new == 0 || moduleId == 4
                    ? 'success'
                    : 'warning'
                "
                v-b-tooltip.hover.top="'Notes'"
              />
              <b-icon
                v-else
                @click="notesModal(data.item, data.index)"
                icon="chat-square-text-fill"
                class="cursor-pointer font-medium-2"
                :variant="data.item.notes_status === 0 ? 'muted' : 'success'"
                v-b-tooltip.hover.top="'Notes'"
              />
            </b-col>
          </template>
          <template v-slot:cell(trackings)="data">
            <b-col class="d-flex justify-content-start p-0">
              <b-icon
                @click="
                  openTrackingModal(
                    data.item.program,
                    data.item.client,
                    data.item.trackings,
                    data.item.id
                  )
                "
                class="cursor-pointer font-medium-2"
                icon="list-check"
                :variant="data.item.trackings ? 'success' : 'muted'"
                v-b-tooltip.hover.top="'Tracking'"
              />
            </b-col>
          </template>
          <template v-slot:cell(files)="data">
            <b-col class="d-flex justify-content-start p-0">
              <feather-icon
                @click="
                  ((data.item.user_id == currentUser.user_id &&
                    (G_IS_SELLER || userCanSell[0].can_sell == 1)) ||
                    G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isChief ||
                    isTeamLeader ||
                    isCoordinator ||
                    isAssistantSupervisor ||
                    isAdministrator) &&
                    openFilesModal(
                      data.item.lead_id,
                      data.item.program,
                      data.item.client,
                      data.item.id,
                      data.item.status,
                      data.item.user_id,
                      data.item.program_id,
                      data.item.event_date
                    )
                "
                :class="
                  (data.item.user_id == currentUser.user_id &&
                    (G_IS_SELLER || userCanSell[0].can_sell == 1)) ||
                  G_IS_CEO ||
                  G_IS_SUPERVISOR ||
                  isCoordinator ||
                  isTeamLeader ||
                  isChief ||
                  isAssistantSupervisor ||
                  isAdministrator
                    ? 'cursor-pointer text-warning'
                    : ''
                "
                :style="
                  (data.item.user_id == currentUser.user_id &&
                    (G_IS_SELLER || userCanSell[0].can_sell == 1)) ||
                  G_IS_CEO ||
                  G_IS_SUPERVISOR ||
                  isCoordinator ||
                  isTeamLeader ||
                  isChief ||
                  isAssistantSupervisor ||
                  isAdministrator
                    ? 'fill: #ff9f43'
                    : 'fill: #D8D8D6'
                "
                icon="FolderIcon"
                class="font-medium-2"
                v-b-tooltip.hover.top="'Files'"
              />
            </b-col>
          </template>

          <template v-slot:cell(status)="data">
            <p
              class="m-0 font-weight-bold font-small-3"
              :class="'color: text-' + status[data.item.status].variant"
            >
              {{ status[data.item.status].label }}
            </p>
          </template>
          <template #cell(creates)="data">
            <span
              class="font-weight-bold text-success"
              v-b-tooltip.hover.top="'Approved'"
              >{{ data.item.approved | myGlobal }}</span
            >
            <br />
            <br />
            <span v-b-tooltip.hover.top="'Created'" class="text-warning">{{
              data.item.creates | myGlobal
            }}</span>
          </template>
          <template v-slot:cell(actions)="data">
            <b-row
              v-if="data.item.creates > '2021-05-16 00:00:00'"
              class="d-flex flex-column"
              :class="{
                'not-pointer':
                  data.item.user_id != currentUser.user_id &&
                  G_IS_SELLER &&
                  !isCoordinator &&
                  !isTeamLeader,
              }"
            >
              <b-button
                v-if="moduleId == 5 && data.item.status == 2"
                variant="outline-success"
                class="w-100 mbc-10"
                size="sm"
                @click="revisionSale(4, data.item, data.index)"
                >Approve</b-button
              >
              <b-button
                v-if="moduleId == 5 && data.item.status == 2"
                variant="outline-warning"
                class="w-100 mbc-10"
                size="sm"
                @click="openModalObserve(8, data.item, data.index)"
                >Underview</b-button
              >
              <b-button
                v-if="moduleId === 5 && data.item.status == 2"
                variant="outline-danger"
                class="w-100"
                size="sm"
                @click="openModalObserve(9, data.item, data.index)"
                >Rejected</b-button
              >
              <!--Start Management Approval Process / Disapproved Sale-->
              <b-button
                v-if="moduleId == 16 && data.item.status == 9"
                variant="outline-success"
                size="sm"
                @click="revisionSale(11, data.item, data.index)"
                >Approve</b-button
              >
              <b-button
                v-if="moduleId == 16 && data.item.status == 9"
                variant="outline-danger"
                class="mt-1"
                size="sm"
                @click="openModalObserve(10, data.item, data.index)"
                >Rejected</b-button
              >
              <!--End Management Approval Process / Disapproved Sale-->
            </b-row>
            <b-row
              v-else
              :class="{
                'not-pointer':
                  data.item.user_id != currentUser.user_id &&
                  G_IS_SELLER &&
                  !isTeamLeader &&
                  !isCoordinator,
              }"
              class="d-flex align-items-center justify-content-center flex-column px-1"
            >
              <!-- Just for Seller after finish all requirements -->
              <b-button
                v-if="
                  (data.item.status == 1 || data.item.status == 7) &&
                  !G_IS_CEO &&
                  !G_IS_SUPERVISOR &&
                  !isCoordinator &&
                  !isTeamLeader &&
                  data.item.contract_fee_status == 1 &&
                  data.item.notes_status == 1 &&
                  data.item.initial_payment_status == 2 &&
                  moduleId !== 4
                "
                variant="outline-success"
                class="m-10px w-100"
                size="sm"
                @click="revisionSale(5, data.item, data.index)"
                >Revission</b-button
              >
              <b-button
                v-if="
                  (data.item.status == 1 || data.item.status == 6) &&
                  (G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief ||
                    isAssistantSupervisor) &&
                  data.item.contract_fee_status == 1 &&
                  data.item.notes_status == 1 &&
                  data.item.initial_payment_status == 2 &&
                  moduleId !== 4
                "
                class="m-10px w-100"
                variant="outline-success"
                :disabled="
                  data.item.type == 1 && G_IS_CEO
                    ? false
                    : data.item.type == 0
                    ? false
                    : true
                "
                size="sm"
                @click="revisionSale(2, data.item, data.index)"
                >Revission</b-button
              >
              <b-button
                v-if="
                  data.item.status == 5 &&
                  (G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief) &&
                  data.item.contract_fee_status == 1 &&
                  data.item.notes_status == 1 &&
                  data.item.initial_payment_status == 2 &&
                  moduleId !== 4
                "
                class="m-10px w-100"
                variant="outline-success"
                size="sm"
                @click="revisionSale(2, data.item, data.index)"
                >Revission</b-button
              >
              <b-button
                v-if="
                  data.item.status == 5 &&
                  (G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief) &&
                  data.item.contract_fee_status == 1 &&
                  data.item.notes_status_new == 0 &&
                  data.item.initial_payment_status == 2 &&
                  moduleId !== 4
                "
                class="m-10px w-100"
                variant="outline-danger"
                size="sm"
                @click="revisionSale(7, data.item, data.index)"
                >Return</b-button
              >
              <b-button
                v-if="
                  data.item.status == 3 &&
                  (currentUser.user_id == data.item.user_id ||
                    G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief) &&
                  data.item.contract_fee_status == 1 &&
                  data.item.notes_status == 1 &&
                  data.item.initial_payment_status == 2 &&
                  moduleId !== 4
                "
                class="m-10px w-100"
                variant="outline-danger"
                size="sm"
                @click="revisionSale(2, data.item, data.index)"
                >Revission</b-button
              >
              <b-button
                v-if="
                  data.item.initial_payment_status == 1 &&
                  (G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief) &&
                  moduleId !== 4
                "
                variant="outline-danger"
                size="sm"
                class="m-10px w-100"
                @click="annulSale(data.item)"
                >ANNUL</b-button
              >
            </b-row>
          </template>

          <!-- <template #cell(approved)="data">
            <span class="font-weight-bold text-info">{{
              data.item.approved | myGlobal
            }}</span>
          </template> -->
          <template #cell(sms)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="$emit('modalSmsOpen', data.item)">
                <feather-icon icon="MessageSquareIcon" />SMS
              </b-dropdown-item>
              <b-dropdown-item @click="$emit('modalHistorySmsOpen', data.item)">
                <feather-icon icon="RotateCcwIcon" />History
              </b-dropdown-item>
              <b-dropdown-item
                variant="danger"
                v-if="
                  moduleId == 26 &&
                  data.item.initial_payment_status == 1 &&
                  data.item.lead_client_derivation_id
                "
                @click="recycleSaleMade(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Recycle </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  data.item.initial_payment_status === 1 &&
                  (data.item.user_id == currentUser.user_id ||
                    G_IS_CEO ||
                    G_IS_SUPERVISOR ||
                    isCoordinator ||
                    isTeamLeader ||
                    isChief)
                "
                @click="$emit('openUrlModal', data.item)"
              >
                <feather-icon icon="LinkIcon" />Url
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(done)="data">
            <b-btn
              v-if="data.item.initial_payment_status == 2"
              variant="outline-info"
              size="sm"
              @click="returnDone(data.item.event_id, null)"
              >Done</b-btn
            >
          </template>

          <template #cell(more)="data">
            <div class="text-center">
              <div>
                <feather-icon
                  icon="InfoIcon"
                  @click="openModalInfo(data.item)"
                  v-b-tooltip.hover="'Info Lead'"
                  class="clickable"
                  style="margin-top: 5px"
                />
              </div>
              <div
                @click="$emit('modalSmsOpen', data.item)"
                class="clickable"
                style="margin-top: 5px"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  v-b-tooltip.hover="'Send SMS'"
                />
              </div>
              <div
                @click="$emit('modalHistorySmsOpen', data.item)"
                class="clickable"
                style="margin-top: 5px"
              >
                <feather-icon
                  icon="AlignJustifyIcon"
                  v-b-tooltip.hover="'History SMS'"
                />
              </div>
            </div>
          </template>

          <template v-if="G_IS_CEO || G_IS_SUPERVISOR || isChief" #custom-foot>
            <b-tr class="bg-info" v-if="moduleId != 4">
              <b-td colspan="2" />
              <b-td
                colspan="2"
                class="text-center text-white font-weight-bolder py-px"
              >
                TCMC
              </b-td>
              <b-td
                colspan="2"
                class="text-center text-white font-weight-bolder py-px"
              >
                TCMS
              </b-td>
              <b-td
                colspan="3"
                class="text-center text-white font-weight-bolder py-px"
              >
                TFEE
              </b-td>
              <b-td
                colspan="3"
                class="text-center text-white font-weight-bolder py-px"
              >
                TIP
              </b-td>
              <b-td
                colspan="2"
                class="text-center text-white font-weight-bolder py-px"
              >
                TMA
              </b-td>
              <b-td :colspan="done === 0 ? 3 : 2" />
            </b-tr>
            <b-tr v-if="moduleId != 4">
              <b-td colspan="2" class="text-right font-weight-bolder py-px">
                Subtotal
              </b-td>
              <b-td colspan="2" class="text-center py-px">
                {{ "$ " + subtotal.tcmc }}
              </b-td>
              <b-td colspan="2" class="text-center py-px">
                {{ "$ " + subtotal.tcms }}
              </b-td>
              <b-td colspan="3" class="text-center py-px">
                {{ "$ " + subtotal.tfee }}
              </b-td>
              <b-td colspan="3" class="text-center py-px">
                {{ "$ " + subtotal.tip }}
              </b-td>
              <b-td colspan="2" class="text-center py-px">
                {{ "$ " + subtotal.tma }}
              </b-td>
              <b-td :colspan="done === 0 ? 3 : 2" />
            </b-tr>
            <b-tr>
              <b-td colspan="2" class="text-right font-weight-bolder py-px">
                Total
              </b-td>
              <b-td colspan="2" class="text-center py-px">
                {{ "$ " + total.tcmc }}
              </b-td>
              <b-td colspan="2" class="text-center py-px">
                {{ "$ " + total.tcms }}
              </b-td>
              <b-td colspan="3" class="text-center py-px">
                {{ "$ " + total.tfee }}
              </b-td>
              <b-td colspan="3" class="text-center py-px">
                {{ "$ " + total.tip }}
              </b-td>
              <b-td colspan="2" class="text-center py-px" v-if="moduleId != 4">
                {{ "$ " + total.tma }}
              </b-td>
              <b-td :colspan="done === 0 ? 3 : 2" />
            </b-tr>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <approve-supervisor-modal
      v-if="modal.approveSupervisorModal"
      :session-id="currentUser.user_id"
      :modul="modalData.approveSupervisorModal.modul"
      :event-id="modalData.approveSupervisorModal.eventId"
      :type-approve="modalData.approveSupervisorModal.typeApprove"
      @approveSupChar="returnDone"
      @click="closeModalApprove"
    />
    <cool-light-box
      ref="coolBox"
      :items="itemsFlyer"
      :index="indexFlyer"
      @close="indexFlyer = null"
    />
    <b-modal
      id="moreInfo"
      ref="moreInfo"
      :title="`Info Lead: ${infoLead.leadName}`"
      size="sm"
      title-class="h3 text-white"
      hide-footer
      v-model="modalInfo"
      @hidden="closeModalInfo"
    >
      <div>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100 badge-name-group">
                <span>DOC TYPE</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="infoLead.docType" type="text" readonly />
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100 badge-name-group">
                <span>O. COUNTRY</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="infoLead.country" type="text" readonly />
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100 badge-name-group">
                <span>WORK STATUS</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="infoLead.workStatus" type="text" readonly />
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100 badge-name-group">
                <span>LEGAL STATUS</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="infoLead.legalStatus" type="text" readonly />
          </b-input-group>
        </b-form-group>
        <b-form-group
          v-if="infoLead.sourcesname == 'Facebook' && infoLead.flyerCode"
        >
          <b-input-group>
            <b-input-group-prepend class="w-35">
              <b-input-group-text class="w-100 badge-name-group">
                <span>FLYER CODE</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input v-model="infoLead.flyerCode" type="text" readonly />
          </b-input-group>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CrmServices from "@/views/crm/services/crm.service";

import vSelect from "vue-select";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import dataFields from "@/views/crm/views/sales-made/components/new-client/fields.data";
import dataFilters from "@/views/crm/views/sales-made/components/new-client/filters.data";
import CrmService from "@/views/crm/services/crm.service";
import { amgApi } from "@/service/axios";
import ApproveSupervisorModal from "@/views/crm/views/sales-made/components/modals/ApproveSupervisorModal.vue";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Vue from "vue";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import moment from "moment";
import { filterPrincipal } from "@/views/commons/components/reports/tabs/debtor-clients/data/filters.data";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import ObservedModal from "@/views/debt-solution/views/sales-made/modal/ObservedModal.vue";
import AnalysisOptionBadge from "../AnalysisOptionBadge.vue";
export default {
  name: "SalesMadeNewComponent",
  components: {
    FilterSlot,
    vSelect,
    ApproveSupervisorModal,
    CoolLightBox,
    ObservedModal,
    AnalysisOptionBadge,
  },
  props: {
    done: {
      required: true,
      type: Number,
    },
    tab: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      contactHourSecondsValidate48hours: null,
      items: [],
      selected: [],
      isBusy: false,
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      modal: {
        tracking: false,
        initial_payment: false,
        captuerd_by_tracking: false,
        files: false,
        programs: false,
        revission: false,
        url: false,
        contract_fee: false,
        notes: false,
        approveSupervisorModal: false,
        modalSms: false,
        modalValidateDateAndHour: false,
      },
      modalData: {
        approveSupervisorModal: {
          modul: 2,
          typeApprove: 1,
          eventId: "",
        },
      },
      selectAll: false,
      selectedIndex: null,
      newRowFromSelectedIndex: null,
      sellers: [],
      statusEmit: false,
      isActiveViewFlyer: false,
      // flyerData:{},
      itemsFlyer: [],
      indexFlyer: null,
      assetsImgUrl: "https://amgsoftpre.s3.amazonaws.com",
      isLoading: false,
      paramsToExport: null,
      modalInfo: false,
      infoLead: {
        leadName: "",
        docType: "-",
        country: "",
        workStatus: "",
        legalStatus: "",
        flyerCode: null,
        sourcesname: "",
      },
    };
  },
  computed: {
    ...mapState({
      // sellers: (state) => state["crm-store"].sellersCrm,
      captured: (state) => state["crm-store"].capturedCrm,
      // TODO HACERLO GLOBAL
      programs: (state) => state["crm-store"].programs,
      sources: (state) => state["crm-store"].sources,
      sts: (state) => state["crm-store"].states,
      countries: (state) => state["crm-store"].countries,
      work_status: (state) => state["crm-store"].work_status,
      legal_status: (state) => state["crm-store"].legal_status,
      stip: (state) => state["crm-store"].statusip,
      status: (state) => state["crm-store"].statusNewClient,
      statusFilter: (state) => state["crm-store"].statusFilter,
      moduleId() {
        return this.$route.matched[0].meta.module;
      },
    }),
    ...mapGetters({
      userCanSell: "auth/userCanSell",
      currentUser: "auth/currentUser",
      G_IS_SUPERVISOR: "auth/isSupervisor",
      G_IS_SELLER: "auth/isSeller",
      G_IS_CEO: "auth/isCeo",
      G_MODULE_ID: "auth/moduleId",
      G_USER_ROLE: "auth/userRole",
      G_USER_SESSION: "auth/userSession",
      G_ROLE_ID: "auth/roleId",
      G_IS_CHIEF: "auth/isChief",
      G_IS_ASSIST_SUPERVISOR: "auth/isAssistantSupervisor",
    }),
    filteredFields() {
      this.fields.forEach((field) => {
        field.visible = true;
      });
      if (this.filter[5].model != null) {
        if (this.filter[5].model.length != 0) {
          this.filter[5].model.forEach((element) => {
            if (element == 17) {
              this.filter[6].visible = true;
            }
          });
        } else {
          this.filter[6].model = null;
          this.filter[6].visible = false;
        }
      }

      if (this.moduleId == 4) {
        // this.fields[16].visible = false
        this.fields[14].visible = false;
      }

      if (this.done === 0) return this.fields.filter((field) => field.visible);
      return this.fields.filter(
        (field) => field.key !== "done" && field.visible
      );
    },

    total() {
      if (this.items[0]) {
        return {
          tcmc: this.items[0].tcmc,
          tcms: this.items[0].tcms,
          tfee: this.items[0].tfee,
          tip: this.items[0].tip,
          tma: this.items[0].tma,
        };
      }
      return {
        tcmc: "0.00",
        tcms: "0.00",
        tfee: "0.00",
        tip: "0.00",
        tma: "0.00",
      };
    },
    subtotal() {
      if (this.items[0]) {
        return {
          tcmc: this.items[0].stcmc,
          tcms: this.items[0].stcms,
          tfee: this.items[0].stfee,
          tip: this.items[0].stip,
          tma: this.items[0].stma,
        };
      }
      return {
        tcmc: "0.00",
        tcms: "0.00",
        tfee: "0.00",
        tip: "0.00",
        tma: "0.00",
      };
    },
    toRoute() {
      let route = "";
      switch (this.moduleId) {
        case 2:
          route = "lead-show";
          break;
        case 26:
          route = "sales-lead-show";
          break;
        case 4:
          route = "administration-sales-made-report";
          break;
        //FROM DS
        case 5:
          route = "lead-show";
          break;
      }
      return route;
    },
    getWidth() {
      return screen.width + "px";
    },
    routeModule() {
      return this.$route.path;
    },
    isSalesmadeRoute() {
      return this.$route.path === "/crm/salesmade/";
    },
  },
  async created() {
    this.filter[8].visible = false;
    this.filter[7].visible = false;
    try {
      await Promise.all([
        this.moduleId === 26 ? this.getSalesSellers() : this.getSellers(),
        // this.getSellers(),
        this.getAllCatchers(),
        this.$store.dispatch("crm-store/getPrograms"),
        this.$store.dispatch("crm-store/getSources"),
        this.$store.dispatch("crm-store/getStates"),
        this.$store.dispatch("crm-store/getCountries"),
      ]);
      const subSources = await amgApi.get("/lead/get-sub-sources-sn");
      const subSourcesData = subSources.data.filter(
        (data) => data.parent_id == 1
      );
      subSourcesData.unshift({
        id: "0",
        name: "ALL",
      });

      //this.filter[3].options = this.sellers;

      this.filter[4].options = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ];

      this.filter[5].options = this.sources;
      this.filter[6].options = subSourcesData;
      this.filter[7].options = this.statusFilter;
      this.filter[8].options = this.programs;
      this.filter[9].options = this.stip;
      this.filter[10].options = this.sts;
      this.filter[11].options = this.countries;
      this.filter[12].options = this.work_status;
      this.filter[13].options = this.legal_status;
    } catch (error) {
      console.error(error);
    }
    this.addPaddingTd();
  },
  methods: {
    openModalInfo(data) {
      this.infoLead.leadName = data.client;
      this.infoLead.docType = data.ssn
        ? "SSN"
        : data.itin
        ? "ITIN"
        : data.cpn
        ? "CPN"
        : "";
      this.infoLead.country = data.country_name;
      this.infoLead.workStatus = data.work_status;
      this.infoLead.legalStatus = data.legal_status;
      this.infoLead.flyerCode = data.flyer_code;
      this.infoLead.sourcesname = data.sourcesname;
      this.modalInfo = true;
    },
    closeModalInfo() {
      this.modalInfo = false;
    },
    async recycleSaleMade(item) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          await Promise.all([
            this.recycleClient(
              item.client_account_id,
              item.lead_client_derivation_id
            ),
            this.annulSaleRecycle(item),
          ]);
          this.showSuccessSwal();
          this.$refs["new-client-done-table"].refresh();
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async recycleClient(clientAccountId, derivationId) {
      const params = {
        derivation_id: derivationId,
        status: 4, // Tracing(2) or Recycled(4)
        type: clientAccountId ? 2 : 1, // Lead(1) or Client(2)
        user_id: this.currentUser.user_id,
        obs: "Recycled from Sales Made",
      };
      await LeadService.updateLeadsAndClients(params);
    },
    async annulSaleRecycle(sale) {
      await amgApi.post("/sales-made/annul-sale", {
        id: sale.id,
        id_event: sale.event_id,
        user: this.currentUser.user_id,
      });
    },
    async getAllCatchers() {
      if (this.moduleId === 26) {
        const data = await DerivationsService.getAllCeUsers();
        if (data.status === 200) {
          this.filter[2].options = data.data;
        }
      } else {
        await this.$store.dispatch("crm-store/getCaptured", {
          module: 2,
          body: {
            roles: "[]",
            type: "1",
          },
        }),
          (this.filter[2].options = this.captured);
      }
    },
    removeFromTable(saleId) {
      this.statusEmit = true;
      if (this.done == 0) {
        const index = this.items.map((el) => el.id).indexOf(saleId);

        if (index !== -1) {
          this.items.splice(index, 1);
        }
      }
      return this.items;
    },
    async getSellers() {
      const type = this.filter[4].model == null ? null : this.filter[4].model;

      const tempSellers = await CrmServices.getSellersCrm(2, {
        roles: "[1,5,2,3,13]",
        type: type,
      });

      const formatedSellers = tempSellers.map((seller) => ({
        id: seller.id,
        label: seller.user_name,
      }));

      this.sellers = [{ id: 0, label: "All" }];
      this.sellers.push(...formatedSellers);

      this.filter[3].options = this.sellers;
      this.filter[3].model = null;
    },
    async getSalesSellers() {
      const sellersItem = await DerivationsService.getSellerItem();
      if (sellersItem.status === 200) {
        const formatSeller = sellersItem.data.map((seller) => ({
          id: seller.id,
          label: seller.user_name,
        }));

        this.sellers = [];
        this.sellers.push(...formatSeller);

        this.filter[3].options = this.sellers;
        this.filter[3].model = null;
      }
    },
    async hideInitialPaymentModal(val) {
      this.modal.initial_payment = false;
      if (val) {
        await this.updateRow();
      }
    },
    closeModalApprove() {
      this.modal.approveSupervisorModal = false;
    },
    async returnDone(eventId, sendSupervisor) {
      this.modalData.approveSupervisorModal.eventId = Array.isArray(eventId)
        ? eventId[0]
        : eventId;
      if (this.G_IS_SELLER && sendSupervisor === null) {
        this.modal.approveSupervisorModal = true;
      } else {
        const result = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure set done this sale"
        );
        if (result.value) {
          try {
            const response = await amgApi.post("/sale/update-sales-set-done", {
              eventId: this.modalData.approveSupervisorModal.eventId,
            });
            if (response.status === 200) {
              this.$refs["new-client-done-table"].refresh();
            }
          } catch (error) {
            this.showErrorSwal(error);
          }
        }
      }
    },
    async search(ctx) {
      this.paramsToExport = ctx;
      //   if (this.moduleId == 2 || this.moduleId == 26) {
      const items = await this.getSaleMade(ctx);
      return items;
      //   } else {
      // const items = await this.getSaleMadeInProcess(ctx);
      // return items;
      //   }
    },

    async getSaleMadeInProcess(ctx) {
      try {
        let sortBy = 11;
        let sortDirection = "desc";
        if (ctx.sortBy === "client") {
          sortBy = 0;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "creates") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "approved") {
          sortBy = 12;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        }

        let filterPrograms =
          this.filter[8].model != null ? this.filter[8].model.toString() : "";
        let filterSourceName =
          this.filter[5].model != null ? this.filter[5].model.toString() : "";
        let filterStatusSeller =
          this.filter[4].model != null ? this.filter[4].model : null;

        const params = {
          captured: this.filter[2].model,
          cr: null,
          from: this.filter[0].model,
          order: sortDirection,
          orderby: sortBy,
          program: filterPrograms ? filterPrograms : "",
          rolsession: this.currentUser.role_id,
          salemade: 1,
          seller: this.filter[3].model,
          sourcesname_id: filterSourceName ? filterSourceName : "",
          state_h: this.filter[10].model,
          status: this.filter[7].model,
          statusip: this.filter[9].model,
          text: this.filterPrincipal.model ? this.filterPrincipal.model : "",
          to: this.filter[1].model,
          per_page: ctx.perPage,
        };

        const data = await CrmService.getSaleMadeInProcess(
          params,
          ctx.currentPage,
          this.done
        );

        this.startPage = data.from;
        this.toPage = data.to;
        if (this.totalRows !== data.total) this.totalRows = data.total;
        const selectedIds = this.selected.map((s) => s.id);
        let index = 0;
        while (selectedIds.length > 0 && index < data.data.length) {
          if (selectedIds.includes(data.data[index].id)) {
            const { id } = data.data[index];
            data.data[index].selected = true;
            const deleted = selectedIds.findIndex((s) => s === id);
            if (deleted !== -1) selectedIds.splice(deleted, 1);
          }
          index += 1;
        }
        this.items = data.data;
        return this.items;
      } catch (error) {
        return [];
      }
    },

    async getSaleMade(ctx) {
      try {
        let sortBy = 11;
        let sortDirection = "desc";
        if (ctx.sortBy === "client") {
          sortBy = 0;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "creates") {
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "approved") {
          sortBy = 12;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        }

        let filterPrograms =
          this.filter[8].model != null ? this.filter[8].model.toString() : "";
        let filterSourceName =
          this.filter[5].model != null ? this.filter[5].model.toString() : "";
        let filterStatusSeller =
          this.filter[4].model != null ? this.filter[4].model : null;

        const salesmade_ids = this.selected.map((sale) => sale.id);
        let typeActive = null;

        switch (this.tab) {
          case 1: // in DS Pending
            typeActive = 2;
            break;
          case 2: // in DS Observer
            typeActive = 8;
            break;
          case 3:
            if (this.moduleId == 5) {
              // in Debt Solution Sales Made / Approved
              typeActive = 4;
            } else {
              // in Management Approval Process / Disapproved Sale / from DS
              typeActive = 9;
            }
            break;
          case 4: // In Management Approval Process / Disapproved Sale / Approved
            typeActive = 10;
            break;
          case 5: // In Management Approval Process / Disapproved Sale / Refected
            typeActive = 11;
            break;
          case 6: // Dissaproved
            typeActive = 3;
            break;
        }

        const params = {
          text: this.filterPrincipal.model,
          status: typeActive,
          program: 4,
          state_h: this.filter[10].model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          orderby: sortBy,
          order: sortDirection,
          captured: this.filter[2].model,
          seller: this.filter[3].model,
          status_seller: filterStatusSeller,
          salemade: 0,
          rolsession: this.currentUser.role_id,
          statusip: this.filter[9].model,
          sourcesname_id: filterSourceName,
          done: this.done,
          per_page: ctx.perPage,
          subSource: this.filter[6].model,
          module_id: 2,
          origin_country: this.filter[11].model,
          work_status: this.filter[12].model,
          legal_status: this.filter[13].model,
          salesmade_ids: salesmade_ids,
          type_doc: this.filter[14].model,
        };

        const data = await CrmService.getSaleMade(params, ctx.currentPage);
        this.startPage = data.from;
        this.toPage = data.to;
        if (this.totalRows !== data.total) this.totalRows = data.total;
        const selectedIds = this.selected.map((s) => s.id);
        let index = 0;
        while (selectedIds.length > 0 && index < data.data.length) {
          if (selectedIds.includes(data.data[index].id)) {
            const { id } = data.data[index];
            data.data[index].selected = true;
            const deleted = selectedIds.findIndex((s) => s === id);
            if (deleted !== -1) selectedIds.splice(deleted, 1);
          }
          index += 1;
        }
        this.items = data.data;
        return this.items;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    openContractFeeModal(data, index) {
      this.selectedIndex = index;

      const dataCF = {
        data,
        index,
      };

      this.$emit("openContractFeeModal", dataCF);
    },

    // Notes
    async notesModal(data, index) {
      this.addPreloader();

      const dataNotes = { data, index };
      this.$emit("notesModal", dataNotes);
    },
    async revisionSale(state, data, index) {
      const dataRevission = {
        state,
        data,
        index,
      };
      this.$emit("revisionSale", dataRevission);
    },
    openModalObserve(state, data, index) {
      const dataRevission = {
        state,
        data,
        index,
      };
      this.$emit("observeSale", dataRevission);
    },
    refresh() {
      this.$refs["new-client-done-table"].refresh();
    },
    async updateRow(index = this.selectedIndex) {
      if (this.moduleId == 5 || this.moduleId == 16) {
        this.$refs["new-client-done-table"].refresh();
        return;
      }
      this.newRowFromSelectedIndex = await window.amgApi.post(
        "/sales-made/get-sale-made-by-sale-id",
        { saleId: this.items[index].id }
      );
      const newRow = this.newRowFromSelectedIndex.data[0];
      const keysNewRow = Object.keys(newRow);
      const keysOldRow = Object.keys(this.items[index]);
      keysNewRow.forEach((key) => {
        if (keysOldRow.includes(key)) {
          Vue.set(this.items[index], key, newRow[key]);
        }
      });
    },
    openTrackingModal(program, client, tabla, saleId) {
      const dataTracking = {
        program,
        client,
        tabla,
        saleId,
      };
      this.$emit("openTrackingModal", dataTracking);
    },
    openTrackingCapturedByModal(program, client, id, type) {
      const dataTrackingCapture = {
        program,
        client,
        id,
        type,
      };

      this.$emit("openTrackingCapturedByModal", dataTrackingCapture);
    },
    async openInitialPaymentModal(data, index) {
      const dataIP = {
        data,
        index,
      };

      this.$emit("openInitialPaymentModal", dataIP);
    },
    openModalProgram(data, index) {
      this.selectedIndex = index;
      const dataPrograms = { data, index };

      this.$emit("openModalProgram", dataPrograms);
    },
    openFilesModal(
      id,
      program,
      client,
      saleId,
      status,
      sellerId,
      programId,
      eventDate
    ) {
      const dataFile = {
        id,
        program,
        client,
        saleId,
        status,
        sellerId,
        programId,
        eventDate,
      };

      this.$emit("openFilesModal", dataFile);
    },
    selectedRow(data) {
      const index = this.selected.findIndex((select) => select.id === data.id);
      if (data.selected === true && index === -1) this.selected.push(data);
      else if (data.selected === false && index !== -1)
        this.selected.splice(index, 1);
    },
    selectedAll() {
      if (this.selectAll) this.items.forEach((item) => (item.selected = true));
      else this.items.forEach((item) => (item.selected = false));
    },
    async saveNewCaptured(captured, capturedNew, userId, user) {
      // eslint-disable-next-line no-param-reassign
      const [capturedNew2] = this.captured.filter(
        (val) => val.id === capturedNew
      );
      if (captured === capturedNew2.label) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.$store.commit("app/SET_LOADING", true);
        try {
          const response = await CrmService.saveNewCaptured({
            capt: capturedNew2.id,
            id: userId,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Se actualizo satisfactoriamente"
            );
          } else return;
          // eslint-disable-next-line no-param-reassign
          user.capturedNew = capturedNew2.id;
          // eslint-disable-next-line no-param-reassign
          user.captured_id = capturedNew2.id;
          // eslint-disable-next-line no-param-reassign
          user.captured = capturedNew2.label;
          // eslint-disable-next-line no-param-reassign
          user.editCaptured = false;
          this.removePreloader();
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.removePreloader();
        }
      }
    },
    async saveNewSeller(seller, sellerNew, userId, user) {
      const [sellerNew2] = this.sellers.filter((val) => val.id === sellerNew);
      if (seller === sellerNew2.label) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.addPreloader();
        try {
          const response = await CrmService.saveNewSeller({
            sel: sellerNew2.id,
            id: userId,
            user: this.currentUser.user_id,
            lead_client_derivation_id: user?.lead_client_derivation_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Se actualizo satisfactoriamente"
            );
          } else return;
          // eslint-disable-next-line no-param-reassign
          user.sellerNew = sellerNew2.id;
          // eslint-disable-next-line no-param-reassign
          user.seller_id = sellerNew2.id;
          // eslint-disable-next-line no-param-reassign
          user.seller = sellerNew2.label;
          // eslint-disable-next-line no-param-reassign
          user.editSeller = false;
          this.removePreloader();
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.removePreloader();
        }
      }
    },
    async saveNewFee(fee, feeNew, userId, user) {
      if (fee === feeNew) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.$store.commit("app/SET_LOADING", true);
        try {
          const response = await CrmService.saveNewFee({
            fee: feeNew,
            id: userId,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showSuccessSwal();
          } else return;
          // eslint-disable-next-line no-param-reassign
          user.fee = user.feeNew;
          // eslint-disable-next-line no-param-reassign
          user.feeNew = user.fee;
          // eslint-disable-next-line no-param-reassign
          user.editFee = false;
          this.$store.commit("app/SET_LOADING", false);
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.$store.commit("app/SET_LOADING", false);
        }
      }
    },
    async insertTrackingDerivation(information) {
      if (this.moduleId === 26) {
        const params = {
          lead_id: information.lead_id,
          sale_status_id: 7,
          seller_id: this.currentUser.user_id,
          sale_id: information.id,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    async annulSale(sale) {
      try {
        const swal = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure annuled this sale"
        );
        if (swal.isConfirmed) {
          const response = await amgApi.post("/sales-made/annul-sale", {
            id: sale.id,
            id_event: sale.event_id,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            await this.insertTrackingDerivation(sale);
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Your sale has been annulled successfully"
            );
            this.$refs["new-client-done-table"].refresh();
          } else {
            this.showErrorSwal();
          }
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "Error",
          "XIcon",
          "Some error, please try again or contact to support"
        );
      }
    },
    openViewFlyer(data) {
      if (this.itemsFlyer.length) this.itemsFlyer.pop();

      this.itemsFlyer.push({
        src: data.flyer_route,
        title: data.flyer_date,
        thumb: data.flyer_thumb,
      });
      this.indexFlyer = this.itemsFlyer[0].src ? 0 : null;
      this.isActiveViewFlyer = true;
    },
    closeViewFlyer() {
      this.isActiveViewFlyer = false;
    },
    showContractFee(data) {
      if (
        data.item.initial_amount > 0 ||
        data.item.initial_payment_status != 1
      ) {
        this.openContractFeeModal(data.item, data.index);
      }
    },
    openBinnacleModal(data, index) {
      if (data.binnacle_origin == "CRM") {
        this.$set(data, "binnacle_cont", 0);
      }
      const dataBinnacle = {
        data,
        index,
      };

      this.$emit("openBinnacleModal", dataBinnacle);
    },
    activeSocketBinnacleCont() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind(
          "binnacle-count-debtsolution-notifications",
          async (response) => {
            const { sale_id, origin } = response.message;
            const index = this.items.findIndex((item) => item.id == sale_id);
            if (index >= 0) {
              this.items[index].binnacle_origin = origin == 1 ? "DS" : "CRM";
              this.items[index].binnacle_cont++;
            }
          }
        );
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
  },
  mounted() {
    this.activeSocketBinnacleCont();
  },
  beforeDestroy() {
    try {
      window.socket.unbind("binnacle-count-debtsolution-notifications");
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.mbc-10 {
  margin-bottom: 10px !important;
}
.w-35 {
  width: 35% !important;
}

.not-pointer {
  pointer-events: none;
  opacity: 0.4;
}

.m-10px {
  margin: 2px;
}

.py-px {
  padding: 12px !important;
}

.mt-07 {
  margin-top: 7px;
}

.ml-07 {
  margin-left: 7px;
}
</style>
