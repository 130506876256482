import { render, staticRenderFns } from "./PendingTable.vue?vue&type=template&id=9484d754&scoped=true"
import script from "./PendingTable.vue?vue&type=script&lang=js"
export * from "./PendingTable.vue?vue&type=script&lang=js"
import style0 from "./PendingTable.vue?vue&type=style&index=0&id=9484d754&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9484d754",
  null
  
)

export default component.exports